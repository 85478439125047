import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import { deleteChecklist } from './checklistsSlice';
import {
  DecoratedUnisonProjectChecklist,
  UndecoratedUnisonProjectChecklist,
} from '@witmetrics/api-client';
import { mergeWithoutDuplicates } from '@/utils/arrays';

type UnisonProjectChecklistsState = {
  byID: Record<string, UndecoratedUnisonProjectChecklist>;
  byUnisonProjectID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byUnisonProjectID: {},
} satisfies UnisonProjectChecklistsState as UnisonProjectChecklistsState;

export const unisonProjectChecklistsSlice = createSlice({
  name: 'unisonProjectChecklists',
  initialState,
  reducers: {
    setUnisonProjectChecklists: (
      state,
      action: PayloadAction<DecoratedUnisonProjectChecklist[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addUnisonProjectChecklist: (
      state,
      action: PayloadAction<DecoratedUnisonProjectChecklist>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateUnisonProjectChecklist: (
      state,
      action: PayloadAction<DecoratedUnisonProjectChecklist>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteUnisonProjectChecklist: (
      state,
      action: PayloadAction<{ unisonProjectID: number; checklistID: number }>
    ) => {
      const key = buildUnisonProjectChecklistKey(
        action.payload.unisonProjectID,
        action.payload.checklistID
      );
      if (!state.byID[key]) return state;
      const projectID = action.payload.unisonProjectID;
      if (state.byUnisonProjectID[projectID]) {
        state.byUnisonProjectID[projectID] = state.byUnisonProjectID[
          projectID
        ].filter((id) => {
          return id !== key;
        });
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byUnisonProjectID: {} };
      })
      .addCase(deleteChecklist, (state, action) => {
        const byID = { ...state.byID };
        const byUnisonProjectID = { ...state.byUnisonProjectID };
        Object.keys(byID).forEach((key) => {
          if (byID[key].checklistID === action.payload.checklistID) {
            delete byID[key];
          }
        });
        Object.keys(byUnisonProjectID).forEach((projectID) => {
          byUnisonProjectID[projectID] = byUnisonProjectID[projectID].filter(
            (key) => {
              return byID[key] !== undefined;
            }
          );
        });
        return { byID, byUnisonProjectID };
      })
      .addDefaultCase(() => {});
  },
});

function formatUnisonProjectChecklist({
  checklist,
  ...unisonProjectChecklist
}: DecoratedUnisonProjectChecklist) {
  return unisonProjectChecklist;
}

function getNextState(
  state: UnisonProjectChecklistsState,
  unisonProjectChecklists: DecoratedUnisonProjectChecklist[]
) {
  const byID = { ...state.byID };
  const byUnisonProjectID = { ...state.byUnisonProjectID };
  unisonProjectChecklists.forEach((upc) => {
    const key = buildUnisonProjectChecklistKey(
      upc.unisonProjectID,
      upc.checklistID
    );
    byID[key] = formatUnisonProjectChecklist(upc);
    if (!byUnisonProjectID[upc.unisonProjectID]) {
      byUnisonProjectID[upc.unisonProjectID] = [];
    }
    byUnisonProjectID[upc.unisonProjectID] = mergeWithoutDuplicates(
      byUnisonProjectID[upc.unisonProjectID],
      [key]
    );
  });
  return { byID, byUnisonProjectID };
}

export function buildUnisonProjectChecklistKey(
  unisonProjectID: number,
  checklistID: number
) {
  return `${unisonProjectID}.${checklistID}`;
}

export const {
  setUnisonProjectChecklists,
  addUnisonProjectChecklist,
  updateUnisonProjectChecklist,
  deleteUnisonProjectChecklist,
} = unisonProjectChecklistsSlice.actions;

export default unisonProjectChecklistsSlice.reducer;
