'use client';
import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UnauthorizedError } from '@witmetrics/api-client';
import { showErrorToaster } from '@/utils/toasters';
import { useActiveAccount } from '@/store/useStore';
import { DIALOGS } from '@/constants/dialogs';
import { useSidebarCollapsed } from '@/hooks/useSidebarCollapsed';
import { usePath } from '@/hooks/usePath';
import type { Dictionary } from '@/types';
import { useSockets } from '@/providers/SocketsProvider';
import { DEFAULT_DIALOGS, getProjectsPath, useStoredAccountID } from './utils';

type AppStateValue = {
  isSidebarCollapsed: boolean | undefined;
  activeProjectID: number | null;
  activeDialogs: Dictionary<boolean>;
  onToggleSidebarCollapsed: (isCollapsed?: boolean) => void;
  setActiveDialogs: Dispatch<SetStateAction<{ [p: string]: boolean }>>;
  onLogin: () => void;
  onApiError: (err: any, message: string, cb?: Function) => void;
  onClearActiveProject: () => void;
  onSelectProject: (projectID: number) => void;
  onOpenDialog: (dialogName: string) => void;
  onCloseDialog: (dialogName: string) => void;
};

const AppStateContext = createContext<AppStateValue>({
  isSidebarCollapsed: false,
  activeProjectID: null,
  activeDialogs: DEFAULT_DIALOGS,
  onToggleSidebarCollapsed: () => null,
  setActiveDialogs: () => null,
  onLogin: () => null,
  onApiError: () => null,
  onClearActiveProject: () => null,
  onSelectProject: () => null,
  onOpenDialog: () => null,
  onCloseDialog: () => null,
});

export default function AppStateProvider({ children }: PropsWithChildren) {
  const loginCallbacks = useRef<Function[]>([]);
  const activeAccount = useActiveAccount();
  const { setApiErrorCallback } = useSockets();
  const { searchParams, navigate } = usePath();
  const [activeProjectID, setActiveProjectID] = useState<number | null>(null);
  const [activeDialogs, setActiveDialogs] = useState(DEFAULT_DIALOGS);
  const [isSidebarCollapsed, toggleSidebarCollapsed] = useSidebarCollapsed();
  const [storedAccountID, setStoredAccountID] = useStoredAccountID();

  useEffect(() => {
    setApiErrorCallback(handleSocketError);
  }, []);

  useEffect(() => {
    if (!activeAccount) return;
    if (activeAccount.accountID !== storedAccountID) {
      setStoredAccountID(activeAccount.accountID);
    }
  }, [activeAccount]);

  const handleOpenDialog = (dialogName: string) => {
    setActiveDialogs((dialogs) => ({ ...dialogs, [dialogName]: true }));
  };

  const handleCloseDialog = (dialogName: string) => {
    setActiveDialogs((dialogs) => ({ ...dialogs, [dialogName]: false }));
  };

  const handleSocketError = () => {
    handleApiError(new UnauthorizedError('', ''), '');
  };

  const handleApiError = (err: any, toasterMessage: string, cb?: Function) => {
    if (err instanceof UnauthorizedError) {
      if (cb) loginCallbacks.current.push(cb);
      if (!activeDialogs[DIALOGS.LOGIN]) handleOpenDialog('login');
    } else {
      showErrorToaster(toasterMessage);
      throw err;
    }
  };

  const handleSetActiveProject = (projectID: number) => {
    if (projectID === activeProjectID) return null;
    setActiveProjectID(projectID);
    // Persist filters and sorting
    const params = { ...Object.fromEntries(searchParams.entries()) };
    navigate(getProjectsPath(projectID), params);
  };

  const handleClearActiveProject = () => {
    setActiveProjectID(null);
    const params = { ...Object.fromEntries(searchParams.entries()) };
    delete params.projectTab;
    navigate(getProjectsPath(null), params);
  };

  const handleLogin = () => {
    if (loginCallbacks.current) {
      loginCallbacks.current.forEach((cb) => cb());
    }
    handleCloseDialog(DIALOGS.LOGIN);
    loginCallbacks.current = [];
  };

  const handleToggleSidebar = (isCollapsed?: boolean) => {
    if (isCollapsed === undefined) {
      toggleSidebarCollapsed(!isSidebarCollapsed);
    } else {
      toggleSidebarCollapsed(isCollapsed);
    }
  };

  const contextValue = {
    isSidebarCollapsed,
    activeProjectID,
    activeDialogs,
    onToggleSidebarCollapsed: handleToggleSidebar,
    setActiveDialogs,
    onLogin: handleLogin,
    onApiError: handleApiError,
    onClearActiveProject: handleClearActiveProject,
    onSelectProject: handleSetActiveProject,
    onOpenDialog: handleOpenDialog,
    onCloseDialog: handleCloseDialog,
  };

  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
