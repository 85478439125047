import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import { deleteConversation } from './conversationsSlice';
import {
  DecoratedUnisonProjectConversation,
  UndecoratedUnisonProjectConversation,
} from '@witmetrics/api-client';
import { mergeWithoutDuplicates } from '@/utils/arrays';

type UnisonProjectConversationsState = {
  byID: Record<string, UndecoratedUnisonProjectConversation>;
  byUnisonProjectID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byUnisonProjectID: {},
} satisfies UnisonProjectConversationsState as UnisonProjectConversationsState;

export const unisonProjectConversationsSlice = createSlice({
  name: 'unisonProjectConversations',
  initialState,
  reducers: {
    setUnisonProjectConversations: (
      state,
      action: PayloadAction<DecoratedUnisonProjectConversation[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addUnisonProjectConversation: (
      state,
      action: PayloadAction<DecoratedUnisonProjectConversation>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateUnisonProjectConversation: (
      state,
      action: PayloadAction<DecoratedUnisonProjectConversation>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteUnisonProjectConversation: (
      state,
      action: PayloadAction<{ unisonProjectID: number; conversationID: number }>
    ) => {
      const key = buildUnisonProjectConversationKey(
        action.payload.unisonProjectID,
        action.payload.conversationID
      );
      if (!state.byID[key]) return state;
      const projectID = action.payload.unisonProjectID;
      if (state.byUnisonProjectID[projectID]) {
        state.byUnisonProjectID[projectID] = state.byUnisonProjectID[
          projectID
        ].filter((id) => {
          return id !== key;
        });
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byUnisonProjectID: {} };
      })
      .addCase(deleteConversation, (state, action) => {
        const byID = { ...state.byID };
        const byUnisonProjectID = { ...state.byUnisonProjectID };
        Object.keys(byID).forEach((key) => {
          if (byID[key].conversationID === action.payload.conversationID) {
            delete byID[key];
          }
        });
        Object.keys(byUnisonProjectID).forEach((projectID) => {
          byUnisonProjectID[projectID] = byUnisonProjectID[projectID].filter(
            (key) => {
              return byID[key] !== undefined;
            }
          );
        });
        return { byID, byUnisonProjectID };
      })
      .addDefaultCase(() => {});
  },
});

function formatUnisonProjectConversation({
  conversation,
  ...unisonProjectConversation
}: DecoratedUnisonProjectConversation) {
  return unisonProjectConversation;
}

function getNextState(
  state: UnisonProjectConversationsState,
  unisonProjectConversations: DecoratedUnisonProjectConversation[]
) {
  const byID = { ...state.byID };
  const byUnisonProjectID = { ...state.byUnisonProjectID };
  unisonProjectConversations.forEach((upc) => {
    const key = buildUnisonProjectConversationKey(
      upc.unisonProjectID,
      upc.conversationID
    );
    byID[key] = formatUnisonProjectConversation(upc);
    if (!byUnisonProjectID[upc.unisonProjectID]) {
      byUnisonProjectID[upc.unisonProjectID] = [];
    }
    byUnisonProjectID[upc.unisonProjectID] = mergeWithoutDuplicates(
      byUnisonProjectID[upc.unisonProjectID],
      [key]
    );
  });
  return { byID, byUnisonProjectID };
}

export function buildUnisonProjectConversationKey(
  unisonProjectID: number,
  conversationID: number
) {
  return `${unisonProjectID}.${conversationID}`;
}

export const {
  setUnisonProjectConversations,
  addUnisonProjectConversation,
  updateUnisonProjectConversation,
  deleteUnisonProjectConversation,
} = unisonProjectConversationsSlice.actions;

export default unisonProjectConversationsSlice.reducer;
