import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { DecoratedFileCollection } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { addUnisonProjectFileCollection } from './unisonProjectFileCollectionsSlice';
import {
  addConversationMessage,
  setConversationMessages,
} from './conversationMessagesSlice';
import { mergeWithoutDuplicates } from '@/utils/arrays';

type FileCollectionsState = {
  byID: Record<string, DecoratedFileCollection>;
  byPracticeID: Record<string, string[]>;
  byParentID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byPracticeID: {},
  byParentID: {},
} satisfies FileCollectionsState as FileCollectionsState;

export const fileCollectionsSlice = createSlice({
  name: 'fileCollections',
  initialState,
  reducers: {
    setFileCollections: (
      state,
      action: PayloadAction<DecoratedFileCollection[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addFileCollection: (
      state,
      action: PayloadAction<DecoratedFileCollection>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateFileCollection: (
      state,
      action: PayloadAction<DecoratedFileCollection>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteFileCollection: (
      state,
      action: PayloadAction<{ fileCollectionID: number }>
    ) => {
      const key = `${action.payload.fileCollectionID}`;
      if (!state.byID[key]) return state;
      const { practiceID } = state.byID[key];
      if (state.byPracticeID[practiceID]) {
        state.byPracticeID[practiceID] = state.byPracticeID[practiceID].filter(
          (id) => {
            return id !== key;
          }
        );
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byPracticeID: {}, byParentID: {} };
      })
      .addCase(addUnisonProjectFileCollection, (state, action) => {
        return getNextState(state, [action.payload.fileCollection]);
      })
      .addCase(setConversationMessages, (state, action) => {
        return getNextState(
          state,
          action.payload.flatMap((m) =>
            m.fileCollections.map((fc) => fc.fileCollection)
          )
        );
      })
      .addCase(addConversationMessage, (state, action) => {
        return getNextState(
          state,
          action.payload.fileCollections.map((fc) => fc.fileCollection)
        );
      })
      .addDefaultCase(() => {});
  },
});

function getNextState(
  state: FileCollectionsState,
  fileCollections: DecoratedFileCollection[]
) {
  const byID = { ...state.byID };
  const byPracticeID = { ...state.byPracticeID };
  const byParentID = { ...state.byParentID };
  fileCollections.forEach((fc) => {
    const key = `${fc.id}`;
    const parentID = `${fc.parentFileCollectionID}`;
    byID[key] = fc;
    // Add to byPracticeID
    if (!byPracticeID[fc.practiceID]) byPracticeID[fc.practiceID] = [];
    byPracticeID[fc.practiceID] = mergeWithoutDuplicates(
      byPracticeID[fc.practiceID],
      [key]
    );
    if (!byParentID[parentID]) byParentID[parentID] = [];
    byParentID[parentID] = mergeWithoutDuplicates(byParentID[parentID], [key]);
  });
  return { byID, byPracticeID, byParentID };
}

export const {
  setFileCollections,
  addFileCollection,
  updateFileCollection,
  deleteFileCollection,
} = fileCollectionsSlice.actions;

export default fileCollectionsSlice.reducer;
