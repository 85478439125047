import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { FetchUnisonProjectFileCollectionResponse } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { mergeWithoutDuplicates } from '@/utils/arrays';

type DecoratedUnisonProjectFileCollection =
  FetchUnisonProjectFileCollectionResponse;

type UndecoratedUnisonProjectFileCollection = Omit<
  DecoratedUnisonProjectFileCollection,
  'fileCollection'
>;

type UnisonProjectFileCollectionsState = {
  byID: Record<string, UndecoratedUnisonProjectFileCollection>;
  byUnisonProjectID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byUnisonProjectID: {},
} satisfies UnisonProjectFileCollectionsState as UnisonProjectFileCollectionsState;

export const unisonProjectFileCollectionsSlice = createSlice({
  name: 'unisonProjectFileCollections',
  initialState,
  reducers: {
    addUnisonProjectFileCollection: (
      state,
      action: PayloadAction<DecoratedUnisonProjectFileCollection>
    ) => {
      return getNextState(state, [
        formatUnisonProjectFileCollection(action.payload),
      ]);
    },
    deleteUnisonProjectFileCollection: (
      state,
      action: PayloadAction<{
        unisonProjectID: number;
        fileCollectionID: number;
      }>
    ) => {
      const key = buildUnisonProjectFileCollectionKey(
        action.payload.unisonProjectID,
        action.payload.fileCollectionID
      );
      if (!state.byID[key]) return state;
      const projectID = action.payload.unisonProjectID;
      if (state.byUnisonProjectID[projectID]) {
        state.byUnisonProjectID[projectID] = state.byUnisonProjectID[
          projectID
        ].filter((id) => {
          return id !== key;
        });
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byUnisonProjectID: {} };
      })
      .addDefaultCase(() => {});
  },
});

function formatUnisonProjectFileCollection({
  fileCollection,
  ...unisonProjectFileCollection
}: DecoratedUnisonProjectFileCollection) {
  return unisonProjectFileCollection;
}

function getNextState(
  state: UnisonProjectFileCollectionsState,
  unisonProjectFileCollections: UndecoratedUnisonProjectFileCollection[]
) {
  const byID = { ...state.byID };
  const byUnisonProjectID = { ...state.byUnisonProjectID };
  unisonProjectFileCollections.forEach((ufc) => {
    const key = buildUnisonProjectFileCollectionKey(
      ufc.unisonProjectID,
      ufc.fileCollectionID
    );
    byID[key] = ufc;
    if (!byUnisonProjectID[ufc.unisonProjectID]) {
      byUnisonProjectID[ufc.unisonProjectID] = [];
    }
    byUnisonProjectID[ufc.unisonProjectID] = mergeWithoutDuplicates(
      byUnisonProjectID[ufc.unisonProjectID],
      [key]
    );
  });
  return { byID, byUnisonProjectID };
}

export function buildUnisonProjectFileCollectionKey(
  unisonProjectID: number,
  fileCollectionID: number
) {
  return `${unisonProjectID}.${fileCollectionID}`;
}

export const {
  addUnisonProjectFileCollection,
  deleteUnisonProjectFileCollection,
} = unisonProjectFileCollectionsSlice.actions;

export default unisonProjectFileCollectionsSlice.reducer;
