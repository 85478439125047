import { ACTIVE_ACCOUNTS } from '@/constants/activeAccounts';
import { RootState } from '@/store/store';
import { PREFERENCE_IDS } from '@/constants/preferences';
import type {
  DecoratedChecklistItem,
  DecoratedConversation,
  DecoratedConversationMessage,
  DecoratedConversationMessageFile,
  DecoratedConversationMessageFileCollection,
  DecoratedConversationUser,
  DecoratedFileCollection,
  DecoratedPracticePreference,
  DecoratedUnisonProject,
  DecoratedUnisonProjectChecklist,
  DecoratedUnisonProjectConversation,
  DecoratedUserPractice,
  UndecoratedFile,
  UndecoratedUserPreference,
} from '@witmetrics/api-client';
import { buildUserPreferenceKey } from '@/store/slices/userPreferencesSlice';

export type Identifier = number | string;

const { PRACTICE, ORGANIZATION } = ACTIVE_ACCOUNTS;

function getAccountID(state: RootState, practiceID?: Identifier) {
  let accountID = practiceID;
  if (!practiceID && state.activeAccount) {
    accountID = state.activeAccount.accountID;
  }
  return accountID;
}

function buildKey(identifier: Identifier | Identifier[]) {
  if (Array.isArray(identifier)) {
    return identifier.join('.');
  } else return `${identifier}`;
}

function filterBlanks<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((item): item is T => item !== null && item !== undefined);
}

function buildFromIDArray<T, IDType>(
  state: RootState,
  sliceName: keyof Omit<RootState, 'activeAccount' | 'currentUser'>,
  buildMethod: (state: RootState, id: IDType) => any,
  idArray: IDType[]
): T[] {
  if (!idArray) return [];
  return filterBlanks(
    idArray
      .filter((id) => Object.keys(state[sliceName].byID).includes(`${id}`))
      .map((id) => buildMethod(state, id))
  );
}

export function buildActiveAccount(state: RootState) {
  if (!state.activeAccount) return state.activeAccount;
  const accountType = state.activeAccount.accountType.toUpperCase();
  const key = buildKey(state.activeAccount.accountID);
  let account;
  if (accountType === PRACTICE) {
    account = state.practices.byID[key];
  } else if (accountType === ORGANIZATION) {
    throw Error('Organization accounts are not currently supported');
  } else {
    throw Error(`Unrecognized account type: "${accountType}"`);
  }
  return { ...state.activeAccount, account };
}

export function buildCurrentUser(state: RootState) {
  if (!state.currentUser) return state.currentUser;
  return {
    ...state.currentUser,
    userPractices: buildUserPractices(state, state.currentUser.id) || [],
    userPreferences: buildUserPreferences(state, state.currentUser.id),
  };
}

export function buildUserPreferences(
  state: RootState,
  userID: Identifier
): { [key: string]: UndecoratedUserPreference } | null {
  const key = buildKey(userID);
  const userPreferences = state.userPreferences.byUserID[key];
  if (!userPreferences) return null;
  return filterBlanks(
    userPreferences.map((id) => buildUserPreference(state, parseInt(id)))
  ).reduce((a, b) => ({ ...a, [b.preference.name]: b }), {});
}

export function buildUserPreference(
  state: RootState,
  userPreferenceID: Identifier
) {
  const key = buildKey(userPreferenceID);
  const userPreference = state.userPreferences.byID[key];
  if (!userPreference) return null;
  return {
    ...userPreference,
    preference: buildPreference(state, userPreference.preferenceID),
  };
}

export function buildPreference(state: RootState, preferenceID: Identifier) {
  const key = buildKey(preferenceID);
  const preference = state.preferences.byID[key];
  return preference || null;
}

export function buildCurrentUserPreference(
  state: RootState,
  preferenceID: Identifier
) {
  if (!state.currentUser) return null;
  const key = buildUserPreferenceKey(state.currentUser.id, preferenceID);
  const userPreference = state.userPreferences.byID[key];
  if (!userPreference) return null;
  return userPreference.value;
}

export function buildDateFormatPreference(state: RootState) {
  return buildCurrentUserPreference(state, PREFERENCE_IDS.DATE_FORMAT) as
    | string
    | undefined;
}

export function buildUserPractices(
  state: RootState,
  userID: Identifier
): DecoratedUserPractice[] | null {
  const key = buildKey(userID);
  const userPracticeIDs = state.userPractices.byUserID[key];
  if (!userPracticeIDs) return null;
  return buildFromIDArray<DecoratedUserPractice, string>(
    state,
    'userPractices',
    buildUserPractice,
    userPracticeIDs
  );
}

export function buildUserPractice(
  state: RootState,
  userPracticeID: Identifier
): DecoratedUserPractice | null {
  const key = buildKey(userPracticeID);
  const userPractice = state.userPractices.byID[key];
  if (!userPractice) return null;
  return {
    ...userPractice,
    practice: buildPractice(state, userPractice.practiceID),
  };
}

export function buildPractice(state: RootState, practiceID: Identifier) {
  return state.practices.byID[buildKey(practiceID)];
}

export function buildPracticePreferences(
  state: RootState,
  practiceID?: Identifier
): { [key: string]: DecoratedPracticePreference } | null {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const key = buildKey(accountID);
  const idArray = state.practicePreferences.byPracticeID[key];
  if (!idArray) return null;
  return buildFromIDArray<DecoratedPracticePreference, string>(
    state,
    'practicePreferences',
    buildPracticePreference,
    idArray
  )!.reduce((a, b) => ({ ...a, [b.preference.name]: b }), {});
}

export function buildPracticePreference(
  state: RootState,
  practicePreferenceID: Identifier
) {
  const key = buildKey(practicePreferenceID);
  const practicePreference = state.practicePreferences.byID[key];
  if (!practicePreference) return null;
  return {
    ...practicePreference,
    preference: buildPreference(state, practicePreference.preferenceID),
  };
}

export function buildLogoPreference(state: RootState, practiceID?: Identifier) {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const key = buildKey([accountID, PREFERENCE_IDS.LOGO]);
  return buildPracticePreference(state, key);
}

export function buildPracticeUnisonProjects(
  state: RootState,
  practiceID?: Identifier
) {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const idArray = state.unisonProjects.byPracticeID[accountID]?.map((id) =>
    parseInt(id)
  );
  return buildFromIDArray<DecoratedUnisonProject, number>(
    state,
    'unisonProjects',
    buildUnisonProject,
    idArray
  );
}

export function buildUnisonProject(
  state: RootState,
  unisonProjectID: Identifier
) {
  const key = buildKey(unisonProjectID);
  const unisonProject = state.unisonProjects.byID[key];
  if (!unisonProject) return null;
  return unisonProject;
}

export function buildChecklistsFromUnisonProjectID(
  state: RootState,
  unisonProjectID: Identifier
) {
  const key = buildKey(unisonProjectID);
  const projectChecklistKeys =
    state.unisonProjectChecklists.byUnisonProjectID[key];
  if (!projectChecklistKeys) return null;
  const unisonProjectChecklists = buildUnisonProjectChecklistsFromIDArray(
    state,
    projectChecklistKeys
  );
  return unisonProjectChecklists?.map((upc) => upc.checklist) || null;
}

export function buildUnisonProjectChecklistsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedUnisonProjectChecklist, Identifier>(
    state,
    'unisonProjectChecklists',
    buildUnisonProjectChecklist,
    idArray
  );
}

export function buildUnisonProjectChecklist(
  state: RootState,
  unisonProjectChecklistID: Identifier
) {
  const key = buildKey(unisonProjectChecklistID);
  const unisonProjectChecklist = state.unisonProjectChecklists.byID[key];
  if (!unisonProjectChecklist) return null;
  return {
    ...unisonProjectChecklist,
    checklist: buildChecklist(state, unisonProjectChecklist.checklistID),
  };
}

export function buildChecklist(state: RootState, checklistID: Identifier) {
  const key = buildKey(checklistID);
  const checklist = state.checklists.byID[key];
  if (!checklist) return null;
  const itemIDs = checklist.itemOrder;
  let items = buildFromIDArray<DecoratedChecklistItem, Identifier>(
    state,
    'checklistItems',
    buildChecklistItem,
    itemIDs
  );
  return {
    ...state.checklists.byID[key],
    items: items || [],
  };
}

export function buildChecklistItem(
  state: RootState,
  checklistItemID: Identifier
) {
  const key = buildKey(checklistItemID);
  return state.checklistItems.byID[key] || null;
}

export function buildFileCollection(
  state: RootState,
  fileCollectionID: Identifier
) {
  const key = buildKey(fileCollectionID);
  return state.fileCollections.byID[key] || null;
}

export function buildFileCollectionsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedFileCollection, Identifier>(
    state,
    'fileCollections',
    buildFileCollection,
    idArray
  );
}

export function buildFileCollectionsFromParentID(
  state: RootState,
  parentFileCollectionID?: Identifier | null
) {
  if (parentFileCollectionID === undefined) return null;
  const key =
    parentFileCollectionID === null ? 'null' : buildKey(parentFileCollectionID);
  const fileCollectionIDs = state.fileCollections.byParentID[key];
  if (!fileCollectionIDs) return null;
  return buildFromIDArray<DecoratedFileCollection, number>(
    state,
    'fileCollections',
    buildFileCollection,
    fileCollectionIDs.map((id) => parseInt(id))
  );
}

export function buildFilesFromIDArray(state: RootState, idArray: Identifier[]) {
  return buildFromIDArray<UndecoratedFile, Identifier>(
    state,
    'files',
    buildFile,
    idArray
  );
}

export function buildFilesFromFileCollectionID(
  state: RootState,
  fileCollectionID: Identifier | null
) {
  if (!fileCollectionID) return [];
  const key = buildKey(fileCollectionID);
  const fileCollectionFileIDs =
    state.fileCollectionFiles.byFileCollectionID[key];
  if (!fileCollectionFileIDs) return null;
  const fileIDs = fileCollectionFileIDs.map((id) => parseInt(id.split('.')[1]));
  return buildFromIDArray<UndecoratedFile, Identifier>(
    state,
    'files',
    buildFile,
    fileIDs
  );
}

export function buildFile(state: RootState, fileID: Identifier) {
  const key = buildKey(fileID);
  return state.files.byID[key] || null;
}

export function buildConversationsFromUnisonProjectID(
  state: RootState,
  unisonProjectID: Identifier
) {
  const key = buildKey(unisonProjectID);
  const projectConversationKeys =
    state.unisonProjectConversations.byUnisonProjectID[key];
  if (!projectConversationKeys) return null;
  const unisonProjectConversations = buildUnisonProjectConversationsFromIDArray(
    state,
    projectConversationKeys
  );
  return unisonProjectConversations?.map((upc) => upc.conversation) || null;
}

export function buildUnisonProjectConversationsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedUnisonProjectConversation, Identifier>(
    state,
    'unisonProjectConversations',
    buildUnisonProjectConversation,
    idArray
  );
}

export function buildUnisonProjectConversation(
  state: RootState,
  unisonProjectConversationID: Identifier
) {
  const key = buildKey(unisonProjectConversationID);
  const unisonProjectConversation = state.unisonProjectConversations.byID[key];
  if (!unisonProjectConversation) return null;
  return {
    ...unisonProjectConversation,
    conversation: buildConversation(
      state,
      unisonProjectConversation.conversationID
    ),
  };
}

export function buildConversationsFromPracticeID(
  state: RootState,
  practiceID: Identifier
) {
  const accountID = getAccountID(state, practiceID);
  if (!accountID) return null;
  const key = buildKey(accountID);
  const conversationKeys = state.conversations.byPracticeID[key];
  if (!conversationKeys) return null;
  return buildConversationsFromIDArray(state, conversationKeys);
}

export function buildConversationsFromIDArray(
  state: RootState,
  idArray: Identifier[]
) {
  return buildFromIDArray<DecoratedConversation, Identifier>(
    state,
    'conversations',
    buildConversation,
    idArray
  );
}

export function buildConversation(
  state: RootState,
  conversationID: Identifier
) {
  const key = buildKey(conversationID);
  if (!state.conversations.byID[key]) return null;
  return {
    ...state.conversations.byID[key],
    users: buildConversationUsers(state, conversationID),
  };
}

export function buildConversationMessages(
  state: RootState,
  conversationID: Identifier
) {
  const key = buildKey(conversationID);
  const messageIDs = state.conversationMessages.byConversationID[key];
  if (!messageIDs) return [];
  return buildFromIDArray<DecoratedConversationMessage, Identifier>(
    state,
    'conversationMessages',
    buildConversationMessage,
    messageIDs.map((id) => parseInt(id))
  );
}

export function buildConversationMessage(
  state: RootState,
  messageID: Identifier
) {
  const key = buildKey(messageID);
  return state.conversationMessages.byID[key] || null;
}

export function buildConversationUsers(
  state: RootState,
  conversationID: Identifier
) {
  const key = buildKey(conversationID);
  const idArray = state.conversationUsers.byConversationID[key];
  if (!idArray) return null;
  return buildFromIDArray<DecoratedConversationUser, Identifier>(
    state,
    'conversationUsers',
    buildConversationUser,
    idArray
  );
}

export function buildConversationUser(
  state: RootState,
  conversationUserID: Identifier
) {
  const key = buildKey(conversationUserID);
  return state.conversationUsers.byID[key] || null;
}

export function buildConversationMessageFiles(
  state: RootState,
  messageID: Identifier
) {
  const key = buildKey(messageID);
  const idArray = state.conversationMessageFiles.byMessageID[key];
  if (!idArray) return [];
  return buildFromIDArray<DecoratedConversationMessageFile, Identifier>(
    state,
    'conversationMessageFiles',
    buildConversationMessageFile,
    idArray
  );
}

export function buildConversationMessageFile(
  state: RootState,
  key: Identifier
) {
  if (!state.conversationMessageFiles.byID[key]) return null;
  return {
    ...state.conversationMessageFiles.byID[key],
    file: buildFile(state, state.conversationMessageFiles.byID[key].fileID),
  };
}

export function buildConversationMessageFileCollections(
  state: RootState,
  messageID: Identifier
) {
  const key = buildKey(messageID);
  const idArray = state.conversationMessageFileCollections.byMessageID[key];
  if (!idArray) return [];
  return buildFromIDArray<
    DecoratedConversationMessageFileCollection,
    Identifier
  >(
    state,
    'conversationMessageFileCollections',
    buildConversationMessageFileCollection,
    idArray
  );
}

export function buildConversationMessageFileCollection(
  state: RootState,
  key: Identifier
) {
  if (!state.conversationMessageFileCollections.byID[key]) return null;
  return {
    ...state.conversationMessageFileCollections.byID[key],
    fileCollection: buildFileCollection(
      state,
      state.conversationMessageFileCollections.byID[key].fileCollectionID
    ),
  };
}

export function buildConversationMessageAttachments(
  state: RootState,
  messageID: Identifier
) {
  const key = buildKey(messageID);
  const idArray = state.conversationMessageFileCollections.byMessageID[key];
  if (!idArray || idArray.length === 0)
    return { files: [], fileCollections: [] };
  const { fileCollectionID } =
    state.conversationMessageFileCollections.byID[idArray[0]];
  const files = buildFilesFromFileCollectionID(state, fileCollectionID);
  const fileCollections = buildFileCollectionsFromParentID(
    state,
    fileCollectionID
  );
  return {
    files: files || [],
    fileCollections: fileCollections || [],
  };
}
