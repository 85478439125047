import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { DecoratedConversationUser } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { deleteConversation, setConversations } from './conversationsSlice';
import { setUnisonProjectConversations } from './unisonProjectConversationsSlice';
import { mergeWithoutDuplicates } from '@/utils/arrays';

type ConversationUsersState = {
  byID: Record<string, DecoratedConversationUser>;
  byConversationID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byConversationID: {},
} satisfies ConversationUsersState as ConversationUsersState;

export const conversationUsersSlice = createSlice({
  name: 'conversationUsers',
  initialState,
  reducers: {
    setConversationUsers: (
      state,
      action: PayloadAction<DecoratedConversationUser[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addConversationUser: (
      state,
      action: PayloadAction<DecoratedConversationUser>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateConversationUser: (
      state,
      action: PayloadAction<DecoratedConversationUser>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteConversationUser: (
      state,
      action: PayloadAction<{ conversationID: number; userID: number }>
    ) => {
      const key = buildConversationUserKey(
        action.payload.conversationID,
        action.payload.userID
      );
      if (!state.byID[key]) return state;
      const { conversationID } = state.byID[key];
      if (state.byConversationID[conversationID]) {
        state.byConversationID[conversationID] = state.byConversationID[
          conversationID
        ].filter((id) => id !== key);
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byConversationID: {} };
      })
      .addCase(setUnisonProjectConversations, (state, action) => {
        return getNextState(
          state,
          action.payload.flatMap((upc) => upc.conversation.users)
        );
      })
      .addCase(setConversations, (state, action) => {
        return getNextState(
          state,
          action.payload.flatMap((c) => c.users)
        );
      })
      .addCase(deleteConversation, (state, action) => {
        const conversationUserIDs =
          state.byConversationID[action.payload.conversationID];
        if (conversationUserIDs) {
          conversationUserIDs.forEach((id) => {
            delete state.byID[id];
          });
          delete state.byConversationID[action.payload.conversationID];
        }
      })
      .addDefaultCase(() => {});
  },
});

function getNextState(
  state: ConversationUsersState,
  conversationUsers: DecoratedConversationUser[]
) {
  const byID = { ...state.byID };
  const byConversationID = { ...state.byConversationID };
  conversationUsers.forEach((c) => {
    const key = buildConversationUserKey(c.conversationID, c.userID);
    byID[key] = c;
    if (!byConversationID[c.conversationID]) {
      byConversationID[c.conversationID] = [];
    }
    byConversationID[c.conversationID] = mergeWithoutDuplicates(
      byConversationID[c.conversationID],
      [key]
    );
  });
  return { byID, byConversationID };
}

export function buildConversationUserKey(
  conversationID: number,
  userID: number
) {
  return `${conversationID}.${userID}`;
}

export const {
  setConversationUsers,
  addConversationUser,
  updateConversationUser,
  deleteConversationUser,
} = conversationUsersSlice.actions;

export default conversationUsersSlice.reducer;
