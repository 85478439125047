import { DIALOGS } from '@/constants/dialogs';
import { useSessionStorage } from '@/hooks/useStorage';
import type { Dictionary } from '@/types';

export const DEFAULT_DIALOGS: Dictionary<boolean> = {
  [DIALOGS.ACCOUNTS]: false,
  [DIALOGS.LOGIN]: false,
  [DIALOGS.SEARCH]: false,
  [DIALOGS.SHORTCUTS]: false,
};

export function useStoredAccountID() {
  return useSessionStorage<number | null>('activeAccountID', null, true);
}

export function isProjectsPage(pathname: string) {
  return /^\/dashboard\/projects\/?$/.test(pathname);
}

export function getProjectsPath(projectID: number | null) {
  return `/dashboard/projects/${projectID === null ? 'home' : projectID}`;
}
